@media (min-width: 640px) {
  .myphx-static-GridColumn {
    padding-left: 0;
  }
}


/* Style system updates to align with Myphoenix container */
.container.cmp-uopcommon-container {
  padding: 0;
  /* Card Variant */
  &--card {
    min-height: 17rem;
    height: 100%;
    width: 100%;
    border-radius: .4rem;
    background-size: cover;
    box-shadow: 0 .2rem .4rem rgba(211, 211, 211, 1);
    padding: 2rem 3rem;
    flex: 1 1 0;
    @include media-breakpoint-up(md) {
      min-height: 18.8rem;
    }
  }

  &--bg-phoenix {
    background: url("/myphx-static/base-styles/icons/myphoenix/single-color/header-logo-icon-gray.svg") no-repeat -5rem -2.6rem;
    @include media-breakpoint-up(md) {
      background-position: calc(50% - 27.7rem) -1.5rem;
    }
    @include media-breakpoint-up(lg) {
      background-position-x: calc(50% - 38.7rem);
    }
  }

  &--bucket {
      min-height: 17rem;
      height: 100%;
      width: 100%;
      border-radius: .4rem;
      background-size: cover;
      padding: 4.5rem;
      flex: 1 1 0;
      border-bottom: 0.3rem solid $color-almost-black-l70;
      @include media-breakpoint-up(md) {
        min-height: 18.8rem;
        box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.2);
        border-bottom: none;
      }

      .text .cmp-text {
        * {
          font-weight: 200;
          margin-top: 1.2rem;
          font-size: 2.4rem;
          line-height: 2.8rem;
          color: $color-black;
        }
      }
    }

  /* Padding Options */
  &--pb-1 {
      padding-bottom: 1rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-bottom: 1rem;
      }
    }
  }

  &--pb-2 {
      padding-bottom: 2rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-bottom: 2rem;
      }
    }
  }

  &--pb-3 {
      padding-bottom: 3rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-bottom: 3rem;
      }
    }
  }

  &--pl-1 {
      padding-left: 1rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-left: 1rem;
      }
    }
  }

  &--pl-2 {
      padding-left: 2rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-left: 2rem;
      }
    }
  }

  &--pl-3 {
      padding-left: 3rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-left: 3.5rem;
      }
    }
  }

  &--pr-1 {
      padding-right: 1rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-right: 1rem;
      }
    }
  }

  &--pr-2 {
      padding-right: 2rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-right: 2rem;
      }
    }
  }

  &--pr-3 {
      padding-right: 3rem;

    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-right: 3.5rem;
      }
    }
  }

  &--pt-1 {
      padding-top: 1rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-top: 1rem;
      }
    }
  }

  &--pt-2 {
      padding-top: 2rem;
    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-top: 2rem;
      }
    }
  }

  &--pt-3 {
      padding-top: 3rem;

    &--mobile-only {
      @include media-breakpoint-only(sm) {
        padding-top: 3rem;
      }
    }
  }

  /* Children Spacing */
  &--cs-1 >  div {
    margin-bottom: 1rem;
  }

  &--cs-2 >  div {
    margin-bottom: 2rem;
  }

  &--cs-3 >  div {
    margin-bottom: 3rem;
  }

  &--cs-4 >  div {
    margin-bottom: 4rem;
  }

  &--cs-5 >  div {
    margin-bottom: 5rem;
  }

  > div:last-child {
    margin-bottom: 0;
  }

  &--desktop-only-border-gray-full {
    border: none;
    @include media-breakpoint-up(md) {
      border: solid 0.2rem $color-light-gray;
    }
  }

  &--desktop-only-border-gray-right {
    border-right: none;
    @include media-breakpoint-up(md) {
      border-right: solid 0.2rem $color-light-gray;
    }
  }

  &--desktop-only-border-gray-left {
    border-left: none;
    @include media-breakpoint-up(md) {
      border-left: solid 0.2rem $color-light-gray;
    }
  }

  &--desktop-only-border-gray-top {
    border-top: none;
    @include media-breakpoint-up(md) {
      border-top: solid 0.2rem $color-light-gray;
    }
  }

  &--desktop-only-border-gray-bottom {
    border-bottom: none;
    @include media-breakpoint-up(md) {
      border-bottom: solid 0.2rem $color-light-gray;
    }
  }

  &--mobile-only-border-gray-bottom {
    border-bottom: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border-bottom: none;
    }
  }

  &--mobile-only-border-gray-top {
    border-top: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border-top: none;
    }
  }

  &--mobile-only-border-gray-right {
    border-right: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border-right: none;
    }
  }

  &--mobile-only-border-gray-left {
    border-left: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border-left: none;
    }
  }

  &--mobile-only-border-gray-full {
    border: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border: none;
    }
  }

  &--border-rounded-corners {
    border-radius: 0.4rem;
  }

  &--border-color-dark-gray {
    border-color: $color-almost-black-l50;
  }
}
