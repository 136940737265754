@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.cmp-title {
  color: #001823;
  h1 {
    font-size: 3.2rem;
    font-weight: 300;
  }
  h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: bold;
  }
  h4 {
    font-size: 1.4rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1rem;
    font-weight: bold;
  }
  h6 {
    font-size: 0.8rem;
    font-weight: bold;
  }

  &--font-family-ibm-plex {
    .cmp-title__text {
      font-family: 'IBM Plex Serif', serif;
    }
  }

  &--font-family-roboto {
    .cmp-title__text {
      font-family: Roboto, sans-serif;
    }
  }

  &--font-weight-300 {
    .cmp-title__text {
      font-weight: 300;
    }
  }

  &--font-weight-400 {
    .cmp-title__text {
      font-weight: 400;
    }
  }

  &--font-weight-500 {
    .cmp-title__text {
      font-weight: 500;
    }
  }

  &--font-weight-600 {
    .cmp-title__text {
      font-weight: 600;
    }
  }

  &--font-weight-700 {
    .cmp-title__text {
      font-weight: 700;
    }
  }

  &--font-weight-800 {
    .cmp-title__text {
      font-weight: 800;
    }
  }

  &--font-weight-900 {
    .cmp-title__text {
      font-weight: 900;
    }
  }

  &--light-text {
    .cmp-title__text {
      color: $color-white;
    }
  }
}

.cmp-title--center-align {
  text-align: center;
}

.cmp-title--left-align {
  text-align: left;
}

.cmp-title--right-align {
  text-align: right;
}

.cmp-title--light-text {
  color: $color-white;
}

.cmp-title.red-underline {
  .cmp-title__text {
    display: inline-block;
    position: relative;
  }

  .cmp-title__text::after {
    content: '';
    height: 0.3rem;
    width: 8.8rem;
    background: $color-phoenix-red;
    position: absolute;
    bottom: -2rem;
    left: 0;
    right: 100%;
  }
}


