/* uopcommon text */

/* Style system */
.text.font-family-ibm-plex{
  p {
    font-family: 'IBM Plex Serif', serif;
  }
}

.text.font-family-roboto{
  p {
    font-family: Roboto, sans-serif;
  }
}

.text.font-weight-300{
  p {
    font-weight: 300;
  }
}

.text.font-weight-400{
  p {
    font-weight: 400;
  }
}

.text.font-weight-500{
  p {
    font-weight: 500;
  }
}

.text.font-weight-600{
  p {
    font-weight: 600;
  }
}

.text.font-weight-700{
  p {
    font-weight: 700;
  }
}

.text.font-weight-800{
  p {
    font-weight: 800;
  }
}

.text.font-weight-900{
  p {
    font-weight: 900;
  }
}
.text {
  &.list-style-disc {
    ul {
      li {
        list-style: disc;
      }
    }
  }
}
