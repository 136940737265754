@import '../../../../styles/variables';
@import '../../../../styles/mixins';

/* Root Container flexes to fill viewport */
.root > .cmp-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main Container flex grows*/
main {
  flex: 1;
}

/* Adjusts top value of anchor navigation for myphoenix / special case for site-stripe presence */
.myphoenix-container {
  .cmp-anchor-navigation {
    top: 7.2rem;
    @include media-breakpoint-up(md) {
      top: 8.2rem;
    }
    &.hasSiteStripe {
      top: 12.5rem;
      @include media-breakpoint-up(md) {
        top: 13.9rem;
      }
    }
    &--inner-content-max-width-xs {
      nav {
        max-width: 80.8rem;
      }
    }
    &--inner-content-max-width-sm {
      nav {
        max-width: 85.3rem;
      }
    }
    &--inner-content-max-width-md {
      nav {
        max-width: 106.7rem;
      }
    }
    &--inner-content-max-width-lg {
      nav {
        max-width: 128rem;
      }
    }
  }
}

/* Section Padding Option */
section {
  &.cmp-container--primary > .cmp-container {
    padding-bottom: 5.8rem;
    padding-top: 0;
    @include media-breakpoint-up(md) {
      padding-bottom: 6.8rem;
      padding-top: 0;
    }
    .personalizedgreeting {
      margin-bottom: 3.6rem;
    }
  }
  &.cmp-container--secondary > .cmp-container {
    padding-bottom: 6.8rem;
    padding-top: 6.8rem;
  }
}

.home-page {
  section {
    &.cmp-container--primary > .cmp-container {
      padding-top: 3.6rem;
      @include media-breakpoint-up(md) {
        padding-top: 6.3rem;
      }
    }
  }
}
.cmp-container {
  padding: 0;
  /* Card Variant */
  &--card > .cmp-container {
    min-height: 17rem;
    height: 100%;
    width: 100%;
    border-radius: .4rem;
    background-size: cover;
    box-shadow: 0 2px 4px rgba(211,211,211,1);
    padding: 2rem 3rem;
    flex: 1 1 0;
    @include media-breakpoint-up(md) {
      min-height: 18.8rem;
    }
  }
  &--bg-phoenix > .cmp-container {
    background: url("/myphx-static/base-styles/icons/myphoenix/single-color/header-logo-icon-gray.svg") no-repeat -5rem -2.6rem;
    @include media-breakpoint-up(md) {
      background-position: calc(50% - 27.7rem) -1.5rem;
    }
    @include media-breakpoint-up(lg) {
      background-position-x: calc(50% - 38.7rem);
    }
  }
  &--bucket {
    > .cmp-container {
      min-height: 17rem;
      height: 100%;
      width: 100%;
      border-radius: .4rem;
      background-size: cover;
      padding: 4.5rem;
      flex: 1 1 0;
      border-bottom: 0.3rem solid $color-almost-black-l70;
      @include media-breakpoint-up(md) {
        min-height: 18.8rem;
        box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.2);
        border-bottom: none;
      }

      .text .cmp-text {
        * {
          font-weight: 200;
          margin-top: 1.2rem;
          font-size: 2.4rem;
          line-height: 2.8rem;
          color: $color-black;
        }
      }
    }
  }
  /* Padding Options */
  &--pb-1 {
    & > .cmp-container {
      padding-bottom: 1rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-bottom: 1rem;
      }
    }
  }
  &--pb-2 {
    & > .cmp-container {
      padding-bottom: 2rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-bottom: 2rem;
      }
    }
  }
  &--pb-3 {
    & > .cmp-container {
      padding-bottom: 3rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-bottom: 3rem;
      }
    }
  }
  &--pl-1 {
    & > .cmp-container {
      padding-left: 1rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-left: 1rem;
      }
    }
  }
  &--pl-2 {
    & > .cmp-container {
      padding-left: 2rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-left: 2rem;
      }
    }
  }
  &--pl-3 {
    & > .cmp-container {
      padding-left: 3rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-left: 3.5rem;
      }
    }
  }
  &--pr-1 {
    & > .cmp-container {
      padding-right: 1rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-right: 1rem;
      }
    }
  }
  &--pr-2 {
    & > .cmp-container {
      padding-right: 2rem;
    }

    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-right: 2rem;
      }
    }
  }
  &--pr-3 {
    & > .cmp-container {
      padding-right: 3rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-right: 3.5rem;
      }
    }
  }
  &--pt-1 {
    & > .cmp-container {
      padding-top: 1rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-top: 1rem;
      }
    }
  }
  &--pt-2 {
    & > .cmp-container {
      padding-top: 2rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-top: 2rem;
      }
    }
  }
  &--pt-3 {
    & > .cmp-container {
      padding-top: 3rem;
    }
    &--mobile-only > .cmp-container {
      @include media-breakpoint-only(sm) {
        padding-top: 3rem;
      }
    }
  }
  /* Children Spacing */
  &--cs-1 > .cmp-container > div {
    margin-bottom: 1rem;
  }
  &--cs-2 > .cmp-container > div {
    margin-bottom: 2rem;
  }
  &--cs-3 > .cmp-container > div {
    margin-bottom: 3rem;
  }
  &--cs-4 > .cmp-container > div {
    margin-bottom: 4rem;
  }
  &--cs-5 > .cmp-container > div {
    margin-bottom: 5rem;
  }
  > div:last-child {
    margin-bottom: 0;
  }

  &--desktop-only-border-gray-full > div.cmp-container {
    @include media-breakpoint-up(md) {
      border: solid 0.2rem $color-light-gray;
    }
  }

  &--desktop-only-border-gray-right > div.cmp-container {
    @include media-breakpoint-up(md) {
      border-right: solid 0.2rem $color-light-gray;
    }
  }

  &--desktop-only-border-gray-left > div.cmp-container {
    @include media-breakpoint-up(md) {
      border-left: solid 0.2rem $color-light-gray;
    }
  }

  &--desktop-only-border-gray-top > div.cmp-container {
    @include media-breakpoint-up(md) {
      border-top: solid 0.2rem $color-light-gray;
    }
  }

  &--desktop-only-border-gray-bottom > div.cmp-container {
    @include media-breakpoint-up(md) {
      border-bottom: solid 0.2rem $color-light-gray;
    }
  }

  &--mobile-only-border-gray-bottom > .cmp-container {
    border-bottom: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border-bottom: none;
    }
  }

  &--mobile-only-border-gray-top > .cmp-container {
    border-top: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border-top: none;
    }
  }

  &--mobile-only-border-gray-right > .cmp-container {
    border-right: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border-right: none;
    }
  }

  &--mobile-only-border-gray-left > .cmp-container {
    border-left: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border-left: none;
    }
  }

  &--mobile-only-border-gray-full > .cmp-container {
    border: solid 0.2rem $color-light-gray;
    @include media-breakpoint-up(md) {
      border: none;
    }
  }

  &--border-box > div.cmp-container {
    @include media-breakpoint-up(md) {
      border: solid 0.1rem $color-almost-black-l50;
      border-radius: 0.4rem;
      padding: 5rem 5rem 4rem;
    }
  }
}
