@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.cmp-card-carousel {
  @include media-breakpoint-up(md) {
    &--standard-gutters {
      .column.card:first-child {
        padding-left: 0;
      }
      .column.card {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .column.card:last-child {
        padding-right: 0;
      }
    }
  }
}