@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.cmp-card {
  &--darker-font  &__title {
    color: $color-mirage;
  }
  &--darker-font &__description {
    color: #5e7079;
  }
  &--smaller-description &__description {
    font-size: 1.4rem;
  }
  @include media-breakpoint-only(md) {
    &--tablet-padding-small > & {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
  @include media-breakpoint-up(md) {
    &--no-description-margin &__description {
      margin-top: 0;
    }
    &--padding-top-small > & {
      padding-top: 2.5rem;
    }
    &--padding-top-medium > & {
      padding-top: 3.5rem;
    }
    &--padding-bottom-small > & {
      padding-bottom: 2.5rem;
    }
    &--padding-bottom-medium > & {
      padding-bottom: 3.5rem;
    }
  }

  &--academic-plan &__icon-wrapper {
    width: 4rem;
    height: 4rem;
    @include media-breakpoint-up(md) {
      width: 5.5rem;
      height: 5.5rem;
      margin-bottom: 1.8rem;
    }

  }
  &--financial-plan &__icon-wrapper {
    width: 4rem;
    height: 4rem;
    @include media-breakpoint-up(md) {
      width: 5.2rem;
      height: 5.2rem;
      margin-top: 0.2rem;
      margin-bottom: 1.9rem;
    }
  }
  &--contacts &__icon-wrapper {
    width: 4.6rem;
    height: 4rem;
    @include media-breakpoint-up(md) {
      width: 7.6rem;
      height: 4.1rem;
      margin-top: 0.8rem;
      margin-bottom: 2.4rem;
    }
  }

  &--vsu {
    .cmp-card {
      height: 21.6rem;
    }
    @include media-breakpoint-up(md) {
      .cmp-card {
        padding: 1rem 1rem;
        &__content-wrapper {
          max-width: 19.5rem;
        }
        &__icon-wrapper {
          width: 9.6rem;
          height: 9.6rem;
        }
      }
    }
  }

}
