@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

// Override bootstrap breakpoints to match with Material
$grid-breakpoints: (
        sm: 0,
        md: 640px,
        lg: 992px,
        xl: 1441px,
);

$container-max-widths: (
        md: 628px,
        lg: 808px,
);

// Variables for MyPhoenix

$font-primary:       'Roboto';
$font-secondary:     'Arial';

// Typography
$font-weight-thin:       300;
$font-weight-normal:     400;
$font-weight-medium:     500;
$font-weight-bold:       700;
$font-weight-black:      900;

// Colors
$color-white: #ffffff;
$color-black: #000000;
$color-system-green: #007d21;
$color-phoenix-red: #db3725;
$color-phoenix-red-d20: #b21f13;
$color-phoenix-red-d40: #8a1500;
$color-phoenix-red-d60: #58160f;
$color-phoenix-red-d80: #2c0b07;
$color-phoenix-red-d90: #150504;
$color-phoenix-red-l20: #ea695b;
$color-phoenix-red-l40: #fc9489;
$color-phoenix-red-l60: #58160f;
$color-phoenix-red-l80: #2c0b07;
$color-phoenix-red-l90: #150504;
$color-almost-black: #001823;
$color-almost-black-d20: #000304;
$color-almost-black-l10: #1d333d;
$color-almost-black-l20: #3a4f59;
$color-almost-black-l30: #5e7079;
$color-gray: $color-almost-black-l30;
$color-almost-black-l40: #7b8a92;
$color-almost-black-l50: #97a3a9;
$color-almost-black-l60: #b2bbbf;
$color-almost-black-l70: #d2d7d9;
$color-almost-black-l80: #e3e6e7;
$color-light-gray: $color-almost-black-l80;
$color-dark-gray: #cccccc;
$color-almost-black-l90: #f2f3f3;
$color-sunrise-orange: #f67b37;
$color-sunrise-orange-d20: #d84c08;
$color-sunrise-orange-d40: #bc340e;
$color-sunrise-orange-l20: #f89a43;
$color-sunrise-orange-l40: #f6b563;
$color-cactus-green: #567248;
$color-cactus-green-d20: #1d3d26;
$color-cactus-green-d40: #182a10;
$color-cactus-green-l20: #5d8b67;
$color-cactus-green-l40: #93cba4;
$color-transparent: #ffffff00;

// Legacy Colors from werkt theme that are still referenced by components
// Grays
$color-ada-gray:         #757575;
$color-ada-large-gray:   #8c8c8c;
$color-icon-gray:        #c8c8c9;
$color-gallery:          #ebebeb;
$color-grays-anatomy:    #eeedf0;
$color-alabaster:        #fafafa;
$color-wild-sand:        #f7f7f7;
// Yellowish green, chartreuse if you will..
$color-lemon-ginger:     #afb42b;
// Orange-ish
$color-pizazz:           #ff9800;
// Blues
$color-web-blue:         #009cad;
$color-skybound:         #0D819C;
$color-teal:             #00798f;
// Brand colors
$color-brand-red:        #dd3826;
$color-dark-red:         #8a1500;
$color-brand-brown:      #3c2320;
$color-brand-dark-blue:  #405e71;
// Dark colors
$color-mirage:           #111c24;
// Beige colors
$color-term-paper:       #eee0e3;
// Lighter beige
$color-term-paper-75:    #f2ee9;
// Lightest beige
$color-term-paper-50:    #f6f4f1;

// Functional Colors
$color-text:             $color-almost-black;
$color-link-ada:         $color-teal;
