@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.cmp-text {
  font-size: 1.4rem;
  line-height: 1.5;

  h2 {
    font-size: 2rem;
    color: $font-primary;
    margin-bottom: 2rem;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0 0 1rem 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
