.cta {
  a.uopx-btn {
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  a.uopx-btn.btn-primary {
    &:hover, &:focus {
      color: #ffffff;
    }
  }
}
