@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.icon-tile {
  width: 14.5rem;
  height: 10rem;

  @include media-breakpoint-between(sm, md) {
    width: 16rem;
   }

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: .6rem;
  background: rgba(255,255,255,.06);

  &:hover {
    a {
      text-decoration: none;
      .right-arrow {
        left: 2rem;
        transition: all 0.4s ease-out;
      }
    }
  }

  a {
    text-align: center;
    font-weight: $font-weight-medium;
  }

  .right-arrow {
    position: relative;
    left: 1rem;
    height: 1.8rem;
  }

  svg:first-child {
    height: 3.6rem;
    width: 3.6rem;
    margin-bottom: .8rem;
  }

  &.default {
    a {
      font-size: 1.6rem;
      color: $color-almost-black;
      &:focus {
        outline: 0.1rem solid $color-almost-black;
        outline-offset: 1rem;
      }
      svg.right-arrow {
        fill: $color-phoenix-red;
      }
    }
  }

  &.white {
    a {
      font-size: 1.6rem;
      color: $color-white;
      &:focus {
        outline: 0.1rem solid $color-white;
        outline-offset: 1rem;
      }
    }
  }

}

