@import '../../../../styles/variables';

.icon-link {
  a {
    font-weight: $font-weight-medium;
    display: inline-block;
  }

  span {
    position: relative;
    top: .15rem;
  }

  svg {
    margin-right: 1.5rem;
  }

  &.white {
    a {
      font-size: 1.8em;
    }
  }

  &.almost-black {
    a {
      font-size: 1.6rem;
    }
    a:hover {
      svg {
        fill: $color-brand-red;
      }
    }
  }

  &.footer {
    a {
      font-size: 1.8rem;
      &:focus {
        outline: 0.1rem solid $color-almost-black;
        outline-offset: 0.3rem;
      }
    }
  }
}

