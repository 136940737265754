.section {
  ul.MuiList-root {
    padding-left: 0;
    li.MuiListItem-root {
      padding-left: 0;
    }
  }
  .section-container {
    .section-content-container {
      .section-content {
        .section-content-padding {
          padding-top: 1rem;
          padding-bottom: 2rem;
        }
      }
    }
  }
}
