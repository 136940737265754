.faq {
  .cmp-faq {
    .faqc14:last-of-type {
      .cmp-faqc {
        border-bottom: none;
      }
    }
    .title {
      line-height: 3rem;
    }

    .cmp-faq_expand_icon {
      margin-bottom: 2rem;
      @media (min-width: 640px) {
        margin-bottom: 0;
      }
    }

    .cmp-faq_expand_icon:after {
      content: '';
      background: url('/myphx-static/base-styles/icons/myphoenix/single-color/large-plus.svg') no-repeat center;
      float: left;
      margin-right: 1rem;
      margin-left: 0;
      width: 1.7rem;
      height: 1.5rem;
      padding-top: 2.3rem;
      padding-right: 0;

      @media (min-width: 640px) {
        width: 1.7rem;
        height: 1.5rem;
      }
    }

    .active:after {
      content: '';
      background: url('/myphx-static/base-styles/icons/myphoenix/single-color/large-minus.svg') no-repeat center;
      width: 2.4rem;
      height: 0.2rem;
      padding-top: 2.3rem;

      @media (min-width: 640px) {
        width: 1.8rem;
        height: 0.1rem;
      }
    }

    @media (min-width: 408px) {
      .cmp-faqc .cmp-faqc-collapsible {
        width: auto;
      }
    }

    @media (min-width: 640px) {
      .myphx-static-GridColumn {
        &:last-child {
          padding-right: 1rem;
        }
      }
    }
  }
    .cmp-faqc {
      padding: 1rem 0;
      .activeContent {
        margin-top: 0;
      }
      .cmp-faqc-header {
        font-size: 1.6rem;
        line-height: 3rem;

        @media (min-width: 640px) {
          font-size: 1.8rem;
          line-height: 2.4rem;
        }
      }

      .cmp-faqc-activeIcon:after {
        content: '';
        background: url('/myphx-static/base-styles/icons/myphoenix/single-color/large-plus.svg') no-repeat center;
        float: left;
        margin-right: 1rem;
        margin-left: 0;
        width: 2.4rem;
        height: 2.4rem;
        padding-top: 2.3rem;

        @media (min-width: 640px) {
          width: 1.8rem;
          height: 1.5rem;
        }
      }

      .activeTab:after {
        content: '';
        background: url('/myphx-static/base-styles/icons/myphoenix/single-color/large-minus.svg') no-repeat center;
        width: 2.4rem;
        height: 0.2rem;

        @media (min-width: 640px) {
          width: 1.8rem;
          height: 0.1rem;
        }
      }

      .active {
        content: '';
        background: url('/myphx-static/base-styles/icons/myphoenix/single-color/large-plus.svg') no-repeat center;
        float: left;
        margin-right: 1rem;
        margin-left: 0;
        width: 2.4rem;
        height: 2.4rem;
        padding-top: 2.3rem;
        float: right;

        @media (min-width: 640px) {
          width: 1.8rem;
          height: 1.5rem;
        }
      }

      .active:after {
        content: '';
        background: url('/myphx-static/base-styles/icons/myphoenix/single-color/large-minus.svg') no-repeat center;
        width: 1.8rem;
        height: 0.1rem;

        @media (min-width: 640px) {
          width: 1.8rem;
          height: 0.1rem;
        }
      }
    }
}
