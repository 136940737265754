.faqc14 {
  .cmp-faqc {
    padding: 1rem 0;
    .activeContent {
      margin-top: 0;
    }
    .cmp-faqc-content {
      width: 100%;
    }
  }

  a {
    &.btn-tertiary, .cmp-card__cta {
      text-decoration: none;
    }
    text-decoration: underline;
  }
}
