// Bootstrap overrides
// Matching our gutter width with Werkt's
$grid-gutter-width: 10px;

@import 'styles/_variables.scss';
@import 'styles/_mixins.scss';
@import 'styles/uopcommon-overrides/uopcommon-overrides.scss';
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/utilities";

@import "myphoenix/components/button/v1/Button.scss";
@import "myphoenix/components/container/v1/Container.scss";
@import "myphoenix/components/card/v1/Card.scss";
@import "myphoenix/components/card-carousel/v1/CardCarousel.scss";
@import "myphoenix/components/text/v1/Text.scss";
@import "myphoenix/components/tile/v1/Tile.scss";
@import "myphoenix/components/title/v1/Title.scss";
@import "myphoenix/components/icon-item/v1/IconItem.stylesystem.scss";


html {
  // Makes it so 1rem = 10px, it also respects the user's custom set font size in their browser for accessibility.
  font-size: 62.5%;
}

body {
  // Overriding reboot, this makes storybook's background add-on work, otherwise it gets overwritten by reboot
  background: none;

  @include media-breakpoint-down(sm) {
    &.mobile-tile-modal {
      height: 100vh;
      overflow: hidden;
    }
  }
}

html, body {
  font-family: $font-primary, $font-secondary;
  color: $color-text;
  // Overriding reboot's 1.5 line-height.
  line-height: 1;
  @media print {
    #onetrust-consent-sdk {
      visibility: hidden;
    }
  }
}

a {
  color: $color-text;
  &:hover {
    // For now there is no default hover state for links, will add when given
    color: $color-text;
  }
}

.white {
  color: $color-white;
  a {
    color: $color-white;
    &:hover {
      // For now there is no default hover state for links, will add when given
      text-decoration: underline;
    }
  }
}

.brand-brown {
  color: $color-brand-brown;
  a {
    color: $color-brand-brown;
    &:hover {
      color: $color-brand-red;
    }
  }
}

a:focus {
  text-decoration: underline;
}
