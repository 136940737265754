/* uopcommon title */

/* Style system */
.title.font-family-ibm-plex {
  h1,h2,h3,h4,h5,h6 {
    font-family: 'IBM Plex Serif', serif;
  }
}

.title.font-family-roboto {
  h1,h2,h3,h4,h5,h6 {
    font-family: Roboto, sans-serif;
  }
}

.title.font-weight-300 {
  h1,h2,h3,h4,h5,h6 {
    font-weight: 300;
  }
}

.title.font-weight-500 {
  h1,h2,h3,h4,h5,h6 {
    font-weight: 500;
  }
}

.title.font-weight-600 {
  h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
  }
}

.title.font-weight-700 {
  h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
  }
}

.title.font-weight-800 {
  h1,h2,h3,h4,h5,h6 {
    font-weight: 800;
  }
}

.title.font-weight-900 {
  h1,h2,h3,h4,h5,h6 {
    font-weight: 900;
  }
}

.title.red-underline {
  div {
    display: inline-block;
    position: relative;
  }

  div::after {
    content: '';
    height: 0.3rem;
    width: 8.8rem;
    background: $color-phoenix-red;
    position: absolute;
    bottom: -1rem;
    left: 0;
    right: 100%;
  }
}
