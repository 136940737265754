/*
This is for AEM style system styles only,
classes here are added to the parent of the react target class

for example on this component:
<div class="style-system-class-added-here">
   <div class="react-icon-item-v1-wrapper"></div>
</div>

All other styles should be added to the jsx styles file.
*/


/* Icon */

// Size
.cmp-icon-item--icon--size-md{
  .cmp-icon-item{
    svg{
      height: 2.4rem;
      width: 2.4rem;
    }
  }
}
.cmp-icon-item--icon-size-lg{
  .cmp-icon-item{
    svg{
      height: 4.8rem;
      width: 4.8rem;
    }
  }
}

.cmp-icon-item--icon-size-xl{
  .cmp-icon-item{
    svg{
      height: 5.8rem;
      width: 5.8rem;
    }
  }
}

//Margin
.cmp-icon-item--icon-margin-top-sm{
  .cmp-icon-item{
    svg{
      margin-top: 1rem;
    }
  }
}

.cmp-icon-item--icon-margin-top-md{
  .cmp-icon-item{
    svg{
      margin-top: 3rem;
    }
  }
}

.cmp-icon-item--icon-margin-top-lg{
  .cmp-icon-item{
    svg{
      margin-top: 5rem;
    }
  }
}
.cmp-icon-item--icon-margin-bottom-sm{
  .cmp-icon-item{
    svg{
      margin-bottom: 1rem;
    }
  }
}

.cmp-icon-item--icon-margin-bottom-md{
  .cmp-icon-item{
    svg{
      margin-bottom: 3rem;
    }
  }
}

.cmp-icon-item--icon-margin-bottom-lg{
  .cmp-icon-item{
    svg{
      margin-bottom: 5rem;
    }
  }
}

/* Main text */

// Weight
.cmp-icon-item--text-light{
  .cmp-icon-item{
    p{
      font-weight: lighter;
    }
  }
}

.cmp-icon-item--text-bold{
  .cmp-icon-item{
    p{
      font-weight: bold;
    }
  }
}

//Font size
.cmp-icon-item--text-size-md{
  .cmp-icon-item{
    p{
      font-size: 1.8rem;
    }
  }
}

.cmp-icon-item--text-size-lg{
  .cmp-icon-item{
    p{
      font-size: 2.4rem;
    }
  }
}

.cmp-icon-item--text-size-lg{
  .cmp-icon-item{
    p{
      font-size: 2.4rem;
    }
  }
}

/* Snippet */

// Weight
.cmp-icon-item--snippet-weight-light{
  .cmp-icon-item{
    span{
      font-weight: lighter;
    }
  }
}

.cmp-icon-item--snippet-weight-bold{
  .cmp-icon-item{
    span{
      font-weight: bold;
    }
  }
}

// font size
.cmp-icon-item--snippet-size-md{
  .cmp-icon-item{
    span{
      font-size: 1.8rem;
    }
  }
}

.cmp-icon-item--snippet-size-lg{
  .cmp-icon-item{
    span{
      font-size: 2.4rem;
    }
  }
}
