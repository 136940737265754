@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.cmp-tile {
  .content-wrapper {
    display: flex;
    width: 100%;
  }
}

.cmp-icon-tile > .cmp-tile {
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: .6rem;
  background: rgba(255,255,255,.06);

  &:hover {
    a {
      text-decoration: none;

      .icon-right-arrow {
        left: 2rem;
        transition: all 0.4s ease-out;
      }
    }
  }

  a {
    font-size: 1.6em;
    color: $color-white;
    text-align: center;
    font-weight: $font-weight-medium;
    .icon-mask-default {
      height: 3.6rem;
      width: 3.6rem;
      background-color: $color-white;
    }

    .icon-right-arrow {
      position: relative;
      left: 1rem;
      height: 2.1rem;
      width: 2.1rem;
      top: .5rem;
    }
  }
}

.course-link > .cmp-tile {
  height: 8rem;
  display: flex;
  text-transform: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: none;
  background-color: inherit;
  border: none;
  border-radius: 0;

  .icon-right-arrow {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    height: 14.2rem;
  }

  &:hover {
    @include media-breakpoint-up(sm) {
      background: $color-white;
      border-radius: 0.4rem;
      box-shadow: 0rem 0.2rem 0.4rem rgba(211, 211, 211, 1);
    }
  }

  .header {
    font-weight: 500;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    color: $color-black;
    @include media-breakpoint-up(sm) {
      font-size: 2.4rem;
      margin-top: 1rem;
    }
  }

  .text {
    display: none;
    color: $color-black;
    @include media-breakpoint-up(sm) {
      display: inline-block;
      position: relative;
      font-size: 1.5rem;
      font-weight: 400;
      margin-top: 0.6rem;
      color: $color-black;
    }
  }

  .icon-mask-default {
    width: 3.5rem;
    height: 3.5rem;
    background-color: $color-skybound;
  }
}

.inline-text-icon > .cmp-tile {
  height: 8rem;
  display: flex;
  text-transform: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: none;
  background-color: inherit;
  border: none;
  border-radius: 0;

  .icon-right-arrow {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    height: 10.2rem;
  }

  .header {
    font-weight: 500;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    color: $color-black;
    @include media-breakpoint-up(sm) {
      font-size: 2.4rem;
      margin-top: 1rem;
    }
  }

  .text {
    display: none;
    color: $color-black;
    @include media-breakpoint-up(sm) {
      display: inline-block;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 700;
      margin-top: 0.6rem;
      color: $color-black;
      text-align: left;
    }
  }

  .icon-mask-default {
    min-width: 4.8rem;
    height: 4.8rem;
    background-color: $color-almost-black;
    @include media-breakpoint-up(sm) {
      margin-right: 2rem;
    }
  }
}

.spacer-tile > .cmp-tile {
  display: flex;
  text-transform: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: none;
  background-color: inherit;
  border: none;
  border-radius: 0;
  width: 100%;
  min-height: 4.2rem;

  .icon-right-arrow {
    display: none;
  }

  .header {
    font-weight: 500;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    color: $color-black;
    @include media-breakpoint-up(sm) {
      font-size: 2.4rem;
      margin-top: 1rem;
    }
  }

  .text {
    display: none;
    color: $color-black;
    @include media-breakpoint-up(sm) {
      display: inline-block;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
      margin-top: 0.6rem;
      color: $color-black;
      text-align: left;
    }
  }

  .icon-mask-default {
    min-width: 4.8rem;
    height: 3.2rem;
    background-color: $color-almost-black-l70;
    @include media-breakpoint-up(sm) {
      margin-right: 2rem;
    }
  }
}