.gray-background {
  .cmp-card-carousel {
    section {
      .card-carousel-container {
        .card-carousel-row {
          .card-carousel-buttons {
            background-color: $color-almost-black-l90;
          }
        }
      }
    }
  }
}

.white-background {
  .cmp-card-carousel {
    section {
      .card-carousel-container {
        .card-carousel-row {
          .card-carousel-buttons {
            background-color: $color-white;
          }
        }
      }
    }
  }
}

.dark-background {
  .cmp-card-carousel {
    section {
      .card-carousel-container {
        .card-carousel-row {
          .card-carousel-buttons {
            background-color: $color-almost-black;
          }
        }
      }
    }
  }
}
