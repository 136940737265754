@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.cmp-button {
  align-items: center;
  display: flex;
  background-color: $color-black;
  border-color: $color-black;
  border-radius: 3rem;
  border-style: solid;
  border-width: 0.2rem;
  flex-direction: row;
  height: 4rem;
  justify-content: center;
  margin: 1rem;

  &__text {
    text-align: center;
    font-size: 1.3rem;
    color: $color-white;
    font-weight: 700;
    position: relative;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    background-color: $color-white;
    margin-right: 1rem;
    position: relative;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    text-decoration: none;
    .cmp-button__text {
      color: $color-black;
    }

    .cmp-button__icon {
      background-color: $color-black;
    }
  }
  &--large {
    .cmp-button {
      height: 5rem;
      padding: 2rem 3rem;
      display: flex;
      &__text {
        font-size: 1.6rem;
        text-align: left;
        font-weight: 400;
      }
    }
  }
}

/** color options **/
.cmp-button--brand-red > .cmp-button {
  background-color: $color-brand-red;
  border-color: $color-brand-red;
  margin: 1rem 0;
  .cmp-button__text {
    color: $color-white;
    font-size: 1.6rem;
    font-weight: 400;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $color-dark-red;
    border-color: $color-dark-red;
    .cmp-button__icon {
      background-color: $color-brand-red;
    }
  }
}

/** color options **/
.cmp-button--hollow-brand-red > .cmp-button {
  background-color: $color-white;
  border-color: $color-brand-red;
  margin: 1rem 0;

  .cmp-button__text {
    color: $color-brand-red;
    font-size: 1.6rem;
    font-weight: 400;
  }

  &:hover,
  &:active,
  &:focus {
    background-color:  $color-white;
    border-color: $color-dark-red;
    .cmp-button__text {
      color: $color-dark-red;
    }
    .cmp-button__icon {
      background-color: $color-dark-red;
    }
  }
}

.cmp-button--skybound > .cmp-button{
  background-color: $color-skybound;
  border-color: $color-skybound;

  &__text {
    color: $color-white;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    .cmp-button__text {
      color: $color-skybound;
    }
    .cmp-button__icon {
      background-color: $color-skybound;
    }
  }
}

/** color options **/
.cmp-button--transparent > .cmp-button {
  background-color: transparent;
  border-color: transparent;

  .cmp-button__text {
    color: $color-black;
  }

  .cmp-button__icon {
    background-color: $color-brand-red;
    margin: 0rem;
    padding: 1rem;
  }

  &__text {
    color: $color-black;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    .cmp-button__text {
      color: $color-black;
      text-decoration: underline;
    }
  }
}

/** Style options **/
.cmp-button--left-align > .cmp-button {
  justify-content: left;
  display: inline-block;
}

.cmp-button--back {
  display: inline-block;
  margin-top: 2.2rem;
  margin-left: 3rem;
  background-color: transparent;
  border-color: transparent;

  .cmp-button {
    margin: 0;
    height: unset;
    .cmp-button__text {
      color: $color-black;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 400;
    }

    .cmp-button__icon {
      background-color: $color-black;
      margin-right: 0.8rem;
      height: 1.5rem;
      width: 2.5rem;
      display: inline-block;
      top: 0.4rem;
    }

    &__text {
      color: $color-black;
    }
  }
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    .cmp-button__text {
      color: $color-black;
      text-decoration: underline;
    }
  }
}
